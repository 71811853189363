// https://mycamper.atlassian.net/wiki/spaces/DEV/pages/1709080577/Solution+proposal+for+Rework+filters
// Make sure that this is synced with backend at all times.
// Always register new filters here
import { filterKeys } from '~/config/filterKeys';

import { CANCELLATION_POLICIES } from '~/config/bookingCancellation';

import {
    ASSET_TYPE_VEHICLE,
    ASSET_SUB_TYPE_CAMPERVAN,
    ASSET_SUB_TYPE_CARAVAN,
    ASSET_SUB_TYPE_MOTORHOME,
    ASSET_SUB_TYPE_OTHER_CAMPER,
} from '~/config/assetTypes';

export const filterTypeValues = {
    MAIN: 'main',
    ADDITIONAL: 'additional',
    HIDDEN: 'hidden',
    SORT: 'sort',
};

export const typeValues = {
    VEHICLE: ASSET_TYPE_VEHICLE,
};

export const subTypeValues = {
    MOTORHOME: ASSET_SUB_TYPE_MOTORHOME,
    CAMPERVAN: ASSET_SUB_TYPE_CAMPERVAN,
    CARAVAN: ASSET_SUB_TYPE_CARAVAN,
    OTHER_CAMPER: ASSET_SUB_TYPE_OTHER_CAMPER,
};

export const dateModeValues = {
    EXACT: 'exact',
    FLEX: 'flex-3',
};

export const maxAllowedDrivingLicenseClassValues = {
    C1: 'C1',
    B: 'B',
};

export const transmissionValues = {
    AUTOMATIC: 'automatic',
    MANUAL: 'manual',
};

export const bookingMethodValues = {
    INSTANT: 'instant',
    BINDING: 'binding',
};

export const towbarSocketValues = {
    SEVEN_PIN: '7pin',
    THIRTEEN_PIN: '13pin',
};

export const winterTyreTypeValues = {
    STUDDED: 'studded',
    NON_STUDDED: 'non_studded',
};

export const sortValues = {
    POPULARITY: 'popularity',
    PRICE: 'price',
    PRICE_DESC: '-price',
    DISTANCE: 'distance',
    RANDOM: 'random',
    COUNTRY: 'country',
};

export const isGeolocatedKey = 'near_me';

const filters = {
    [filterKeys.ID]: {
        default: null,
        type: filterTypeValues.HIDDEN,
    },
    [filterKeys.OWNER_ID]: {
        default: null,
        type: filterTypeValues.HIDDEN,
    },
    [filterKeys.TYPE]: {
        default: typeValues.VEHICLE,
        options: [{ value: typeValues.VEHICLE }],
        type: filterTypeValues.HIDDEN,
    },
    [filterKeys.SUB_TYPE]: {
        default: null,
        options: [
            { value: subTypeValues.MOTORHOME },
            { value: subTypeValues.CAMPERVAN },
            { value: subTypeValues.CARAVAN },
            { value: subTypeValues.OTHER_CAMPER },
        ],
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.IS_FEATURED]: {
        default: null,
        type: filterTypeValues.HIDDEN,
    },
    [filterKeys.SLEEPS_AT_LEAST]: {
        default: 0,
        options: [Array.from({ length: 18 }, (_, i) => ({ value: i }))], // 0 - 18
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.SITS_AT_LEAST]: {
        default: 0,
        options: [Array.from({ length: 10 }, (_, i) => ({ value: i }))], // 0 - 10
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.SMOKING_ALLOWED]: {
        default: null,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.PETS_ALLOWED]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.CHILDREN_ALLOWED]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.FESTIVALS_ALLOWED]: {
        default: null,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.WINTERIZED]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.MAX_LENGTH]: {
        default: null,
        options: [
            { value: null, label: 'vehicles_filter_dialog.boolean_filter_show_all' },
            { value: 500, label: 'vehicle_fields.max_length_value', labelParams: { count: 5 } },
            { value: 600, label: 'vehicle_fields.max_length_value', labelParams: { count: 6 } },
            { value: 700, label: 'vehicle_fields.max_length_value', labelParams: { count: 7 } },
            { value: 800, label: 'vehicle_fields.max_length_value', labelParams: { count: 8 } },
            { value: 900, label: 'vehicle_fields.max_length_value', labelParams: { count: 9 } },
        ],
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.AVAILABLE_BETWEEN]: {
        // format: {from},{to},{mode}
        default: {
            from: null,
            to: null,
            mode: dateModeValues.EXACT,
        },
        modes: [dateModeValues.EXACT, dateModeValues.FLEX],
        requiresCustomQueryHandling: true,
        type: filterTypeValues.MAIN,
    },
    [filterKeys.MAX_ALLOWED_DRIVING_LICENSE_CLASS]: {
        default: null,
        options: [
            {
                value: maxAllowedDrivingLicenseClassValues.B,
                label: 'vehicles_filter_dialog.driving_license_options.B',
                info: `vehicles_filter_dialog.driving_license_options.${maxAllowedDrivingLicenseClassValues.B}_info`,
            },
            {
                value: maxAllowedDrivingLicenseClassValues.C1,
                label: 'vehicles_filter_dialog.driving_license_options.C1',
                info: `vehicles_filter_dialog.driving_license_options.${maxAllowedDrivingLicenseClassValues.C1}_info`,
            },
        ],
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.TRANSMISSION]: {
        default: null,
        options: [
            {
                value: transmissionValues.AUTOMATIC,
                label: 'vehicle_fields.transmission_types.automatic',
            },
            { value: transmissionValues.MANUAL, label: 'vehicle_fields.transmission_types.manual' },
        ],
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.BRAND_ID]: {
        default: null,
        options: [], // Empty array as fallback until dynamic options are loaded
        label: 'vehicle_fields.brand_label',
        type: filterTypeValues.ADDITIONAL,
        isDynamic: true, // Options are fetched from store.getters.dynamicFilterOptions.brands
    },
    [filterKeys.CHILD_SAFETY_SEAT]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.MINIMUM_AGE]: {
        default: null,
        options: [{ value: null }, ...Array.from({ length: 48 - 18 + 1 }, (_, i) => ({ value: i + 18 }))], // null, 18 - 48
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.MAP_BOUNDS]: {
        // format: {latFrom},{lngFrom},{latTo},{lngTo}
        default: null,
        requiresCustomQueryHandling: true,
        type: filterTypeValues.HIDDEN,
    },
    [filterKeys.LOCATION]: {
        // format: {lat},{lng} (should be sent to backend as {lat},{lng},{radius})
        default: null,
        requiresCustomQueryHandling: true,
        type: filterTypeValues.MAIN,
    },
    [filterKeys.RADIUS]: {
        default: 60,
        options: [
            { value: 10 },
            { value: 20 },
            { value: 30 },
            { value: 40 },
            { value: 50 },
            { value: 60 },
            { value: 70 },
            { value: 80 },
            { value: 90 },
            { value: 100 },
            { value: 150 },
            { value: 200 },
        ],
        excludedFromBackendQuery: true,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.PLACE_DESCRIPTION]: {
        default: null,
        excludedFromBackendQuery: true,
        type: filterTypeValues.MAIN, // A hidden filter but have to clear it with other main filters
    },
    [filterKeys.COUNTRY]: {
        default: null,
        excludedFromBackendQuery: true,
        type: filterTypeValues.MAIN, // A hidden filter but have to clear it with other main filters
    },
    [filterKeys.PRICE_RANGE]: {
        // format: {from},{to},{currency}
        default: null,
        requiresCustomQueryHandling: true,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.BOOKING_METHOD]: {
        default: false,
        options: [{ value: false }, { value: bookingMethodValues.INSTANT }, { value: bookingMethodValues.BINDING }],
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.TOP_OWNER]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.MIN_INCLUDED_DISTANCE_PER_NIGHT]: {
        default: null,
        options: [
            { value: null, label: 'vehicles_filter_dialog.boolean_filter_show_all' },
            { value: '0,200', label: 'vehicle_fields.included_distance_up_to', labelParams: { num: 200 } },
            {
                value: '200,null',
                label: 'vehicle_fields.included_distance_more_than',
                labelParams: { num: 200 },
            },
            { value: -1, label: 'vehicle_fields.included_distance_unlimited' },
        ],
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.CANCELLATION_POLICY]: {
        default: null,
        options: [
            { value: null, label: 'vehicles_filter_dialog.boolean_filter_show_all' },
            { value: CANCELLATION_POLICIES.FLEX, label: 'vehicle_fields.cancellation_policy.flex' },
            { value: CANCELLATION_POLICIES.STANDARD, label: 'vehicle_fields.cancellation_policy.standard' },
            { value: CANCELLATION_POLICIES.STRICT, label: 'vehicle_fields.cancellation_policy.strict' },
        ],
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.MAX_VEHICLE_AGE]: {
        default: null,
        options: [
            { value: null, label: 'vehicles_filter_dialog.boolean_filter_show_all' },
            { value: '0,5', label: 'vehicle_fields.vehicle_age_less_than', labelParams: { num: 5 } },
            { value: '5,10', label: 'vehicle_fields.vehicle_age_between', labelParams: { from: 5, to: 10 } },
            { value: '11,15', label: 'vehicle_fields.vehicle_age_between', labelParams: { from: 11, to: 15 } },
            { value: '16,20', label: 'vehicle_fields.vehicle_age_between', labelParams: { from: 16, to: 20 } },
            { value: '20,null', label: 'vehicle_fields.vehicle_age_more_than', labelParams: { num: 20 } },
        ],
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.MAX_MAXIMUM_AUTHORIZED_MASS]: {
        default: null,
        options: [
            { value: null, label: 'vehicles_filter_dialog.boolean_filter_show_all' },
            { value: 3500, label: 'vehicle_fields.max_mass_up_to', labelParams: { num: 3.5 } },
            { value: 7500, label: 'vehicle_fields.max_mass_up_to', labelParams: { num: 7.5 } },
        ],
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.WINTER_TYRE_TYPE]: {
        default: null,
        options: [
            { value: null },
            { value: winterTyreTypeValues.STUDDED },
            { value: winterTyreTypeValues.NON_STUDDED },
        ],
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.AIR_CONDITION]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.AIR_CONDITION_DRIVING]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.AUX_PORT]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.AWNING]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.AWNING_CARPET]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.BACKUP_CAMERA]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.BARBEQUE_GRILL]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.BED_LINEN_INCLUDED]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.BICYCLE_CARRIER]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.BLACKOUT_CURTAIN]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.BLINDS]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.BLUETOOTH_AUDIO]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.CD_PLAYER]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.COFFEE_MACHINE]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.FLOOR_HEATING]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.FREEZER]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.FULLY_EQUIPPED_KITCHEN]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.GPS]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.HEATING]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.HOT_WATER]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.MARQUIS]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.MICROWAVE_OVEN]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.MOSQUITO_NET]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.MOVER]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.OUTDOOR_FURNITURE]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.OVEN]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.PARKING_SENSORS]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.PILLOWS_AND_BLANKETS_INCLUDED]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.REFRIGERATOR]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.RENTABLE_OUTSIDE_OF_SWITZERLAND]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.OMOCOM_INTERIOR_INSURANCE_ADDON]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.SHOWER]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.SOLAR_PANEL]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.STOVE]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.TOILET]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.TOWBAR]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.TOWBAR_SOCKET]: {
        default: null,
        options: [
            { value: null, label: 'vehicles_filter_dialog.boolean_filter_show_all' },
            { value: towbarSocketValues.SEVEN_PIN, label: 'vehicle_fields.towbar_socket_types.old' },
            { value: towbarSocketValues.THIRTEEN_PIN, label: 'vehicle_fields.towbar_socket_types.new' },
        ],
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.TOWBAR_SOCKET_ADAPTER]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.TOWING_MIRRORS]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.TV]: {
        default: false,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.SEARCH]: {
        default: null,
        type: filterTypeValues.ADDITIONAL,
    },
    [filterKeys.SORT]: {
        default: sortValues.POPULARITY,
        options: [
            { value: sortValues.POPULARITY, label: 'search_form.sort_options_popularity' },
            { value: sortValues.PRICE, label: 'search_form.sort_options_price_ascending' },
            { value: sortValues.PRICE_DESC, label: 'search_form.sort_options_price_descending' },
        ],
        type: filterTypeValues.SORT,
    },
};

/**
 * Gets the filter options for a single filter key
 *
 * @param {string} filterKey Filter key
 * @returns {Array} Filter options
 */
export const getFilterOptions = filterKey => {
    if (!filters[filterKey]) {
        throw new TypeError(`Filter with key ${filterKey} does not exist`);
    }

    return filters[filterKey].options || [];
};

/**
 * Gets the filter options for multiple given filter keys
 *
 * @param {...string} args Filter keys
 * @returns {Object} Filter options
 */
export const getMultipleFilterOptions = (...args) => {
    const filterKeys = Array.from(args);
    const options = {};

    filterKeys.forEach(filterKey => {
        if (!filters[filterKey]) {
            throw new TypeError(`Filter with key ${filterKey} does not exist`);
        }

        options[filterKey] = filters[filterKey].options || [];
    });

    return options;
};

/**
 * Gets the main filter keys
 *
 * @returns {Array} Main filter keys
 */
export const getMainFilterKeys = () => {
    return Object.keys(filters).filter(key => filters[key].type === filterTypeValues.MAIN);
};

/**
 * Gets the additional filter keys
 *
 * @returns {Object} Additional filter keys
 */
export const getAdditionalFiltersKeys = () => {
    const additionalFiltersKeys = Object.keys(filters).filter(key => filters[key].type === filterTypeValues.ADDITIONAL);

    return additionalFiltersKeys;
};

/**
 * Gets the additional filter default values
 *
 * @returns {Object} Additional filter default values
 */
export const getAdditionalFiltersDefaultValues = () => {
    const additionalFilters = {};

    for (const key in filters) {
        if (filters[key].type === filterTypeValues.ADDITIONAL) {
            additionalFilters[key] = filters[key].default;
        }
    }

    return additionalFilters;
};

/**
 * Gets the filter keys of filters that can be activated by default in certain conditions
 *
 * @returns {Array} Conditionally auto activated filter keys
 */
export const getAutoActivatableFilterKeys = () => {
    return [filterKeys.MAX_ALLOWED_DRIVING_LICENSE_CLASS, filterKeys.RENTABLE_OUTSIDE_OF_SWITZERLAND];
};

export default filters;
