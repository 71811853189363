export const ID = 'id';
export const OWNER_ID = 'ownerId';
export const TYPE = 'type';
export const SUB_TYPE = 'subType';
export const IS_FEATURED = 'isFeatured';
export const SLEEPS_AT_LEAST = 'sleepsAtLeast';
export const SITS_AT_LEAST = 'sitsAtLeast';
export const SMOKING_ALLOWED = 'smokingAllowed';
export const PETS_ALLOWED = 'petsAllowed';
export const CHILDREN_ALLOWED = 'childrenAllowed';
export const FESTIVALS_ALLOWED = 'festivalsAllowed';
export const WINTERIZED = 'winterized';
export const MAX_LENGTH = 'maxLength';
export const AVAILABLE_BETWEEN = 'availableBetween';
export const MAX_ALLOWED_DRIVING_LICENSE_CLASS = 'maxAllowedDrivingLicenseClass';
export const TRANSMISSION = 'transmission';
export const BRAND_ID = 'brandId';
export const CHILD_SAFETY_SEAT = 'childSafetySeat';
export const MINIMUM_AGE = 'minimumAge';
export const MAP_BOUNDS = 'mapBounds';
export const LOCATION = 'location';
export const PRICE_RANGE = 'priceRange';
export const BOOKING_METHOD = 'bookingMethod';
export const TOP_OWNER = 'topOwner';
export const MIN_INCLUDED_DISTANCE_PER_NIGHT = 'includedDistancePerNight';
export const CANCELLATION_POLICY = 'cancellationPolicy';
export const MAX_VEHICLE_AGE = 'vehicleAge';
export const MAX_MAXIMUM_AUTHORIZED_MASS = 'maxMaximumAuthorizedMass';
export const WINTER_TYRE_TYPE = 'winterTyreType';
export const AIR_CONDITION = 'airCondition';
export const AIR_CONDITION_DRIVING = 'airConditionDriving';
export const AUX_PORT = 'auxPort';
export const AWNING = 'awning';
export const AWNING_CARPET = 'awningCarpet';
export const BACKUP_CAMERA = 'backupCamera';
export const BARBEQUE_GRILL = 'barbequeGrill';
export const BED_LINEN_INCLUDED = 'bedLinenIncluded';
export const BICYCLE_CARRIER = 'bicycleCarrier';
export const BLACKOUT_CURTAIN = 'blackoutCurtain';
export const BLINDS = 'blinds';
export const BLUETOOTH_AUDIO = 'bluetoothAudio';
export const CD_PLAYER = 'cdPlayer';
export const COFFEE_MACHINE = 'coffeeMachine';
export const FLOOR_HEATING = 'floorHeating';
export const FREEZER = 'freezer';
export const FULLY_EQUIPPED_KITCHEN = 'fullyEquippedKitchen';
export const GPS = 'gps';
export const HEATING = 'heating';
export const HOT_WATER = 'hotWater';
export const MARQUIS = 'marquis';
export const MICROWAVE_OVEN = 'microwaveOven';
export const MOSQUITO_NET = 'mosquitoNet';
export const MOVER = 'mover';
export const OUTDOOR_FURNITURE = 'outdoorFurniture';
export const OVEN = 'oven';
export const PARKING_SENSORS = 'parkingSensors';
export const PILLOWS_AND_BLANKETS_INCLUDED = 'pillowsAndBlanketsIncluded';
export const REFRIGERATOR = 'refrigerator';
export const RENTABLE_OUTSIDE_OF_SWITZERLAND = 'rentableOutsideOfSwitzerland';
export const OMOCOM_INTERIOR_INSURANCE_ADDON = 'omocomInteriorInsuranceAddon';
export const SHOWER = 'shower';
export const SOLAR_PANEL = 'solarPanel';
export const STOVE = 'stove';
export const TOILET = 'hasToilet';
export const TOWBAR = 'towbar';
export const TOWBAR_SOCKET = 'towbarSocket';
export const TOWBAR_SOCKET_ADAPTER = 'towbarSocketAdapter';
export const TOWING_MIRRORS = 'towingMirrors';
export const TV = 'tv';
export const SEARCH = 'search';
export const SORT = 'sort';
export const RADIUS = 'radius';
export const PLACE_DESCRIPTION = 'placeDescription';
export const COUNTRY = 'country';
export const UNKNOWN_QUERY_PARAM = 'unknown_query_param';

export const filterKeys = {
    ID,
    OWNER_ID,
    TYPE,
    SUB_TYPE,
    IS_FEATURED,
    SLEEPS_AT_LEAST,
    SITS_AT_LEAST,
    SMOKING_ALLOWED,
    PETS_ALLOWED,
    CHILDREN_ALLOWED,
    FESTIVALS_ALLOWED,
    WINTERIZED,
    MAX_LENGTH,
    AVAILABLE_BETWEEN,
    MAX_ALLOWED_DRIVING_LICENSE_CLASS,
    TRANSMISSION,
    BRAND_ID,
    CHILD_SAFETY_SEAT,
    MINIMUM_AGE,
    MAP_BOUNDS,
    LOCATION,
    PRICE_RANGE,
    BOOKING_METHOD,
    TOP_OWNER,
    MIN_INCLUDED_DISTANCE_PER_NIGHT,
    CANCELLATION_POLICY,
    MAX_VEHICLE_AGE,
    MAX_MAXIMUM_AUTHORIZED_MASS,
    WINTER_TYRE_TYPE,
    AIR_CONDITION,
    AIR_CONDITION_DRIVING,
    AUX_PORT,
    AWNING,
    AWNING_CARPET,
    BACKUP_CAMERA,
    BARBEQUE_GRILL,
    BED_LINEN_INCLUDED,
    BICYCLE_CARRIER,
    BLACKOUT_CURTAIN,
    BLINDS,
    BLUETOOTH_AUDIO,
    CD_PLAYER,
    COFFEE_MACHINE,
    FLOOR_HEATING,
    FREEZER,
    FULLY_EQUIPPED_KITCHEN,
    GPS,
    HEATING,
    HOT_WATER,
    MARQUIS,
    MICROWAVE_OVEN,
    MOSQUITO_NET,
    MOVER,
    OUTDOOR_FURNITURE,
    OVEN,
    PARKING_SENSORS,
    PILLOWS_AND_BLANKETS_INCLUDED,
    REFRIGERATOR,
    RENTABLE_OUTSIDE_OF_SWITZERLAND,
    OMOCOM_INTERIOR_INSURANCE_ADDON,
    SHOWER,
    SOLAR_PANEL,
    STOVE,
    TOILET,
    TOWBAR,
    TOWBAR_SOCKET,
    TOWBAR_SOCKET_ADAPTER,
    TOWING_MIRRORS,
    TV,
    SEARCH,
    SORT,
    RADIUS,
    PLACE_DESCRIPTION,
    COUNTRY,
    UNKNOWN_QUERY_PARAM,
};
