/* eslint-disable no-param-reassign, no-shadow */

export const mutationTypes = {
    SET_FLAGS: 'FEATURE_FLAGS/SET_FLAGS',
};

export const actionTypes = {
    SET_FLAGS: 'FEATURE_FLAGS/SET_FLAGS',
};

const initialState = () => ({
    flags: [],
    isLoaded: false,
});

export const state = initialState;

export const getters = {
    featureFlags: state => state.flags,
    featureFlagsLoaded: state => state.isLoaded,
    isFeatureEnabled:
        state =>
        (flag, enabledValue = true) => {
            return state.flags.includes(flag) && state.flags[flag] === enabledValue;
        },
    isNewBookingProcessEnabled: state => {
        const chFeatureFlag = state.flags['experiment-new-booking-process-2024'] || '';
        const comFeatureFlag = state.flags['experiment-new-booking-process-2024-com'] || '';

        return chFeatureFlag === 'new' || comFeatureFlag === 'new';
    },
    isOmocomRemake2025Released: (_state, getters) => {
        return getters.isFeatureEnabled('omocom-remake-2025-released', true);
    },
};

export const mutations = {
    [mutationTypes.SET_FLAGS](state, flags) {
        state.flags = flags;
        state.isLoaded = true;
    },
};

export const actions = {
    [actionTypes.SET_FLAGS]({ commit }, flags) {
        commit(mutationTypes.SET_FLAGS, flags);
    },
};

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
};
