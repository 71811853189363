<template>
    <div class="language-switcher__main-content">
        <div v-for="languageRow in formattedLocales" :key="languageRow.key" class="language-switcher__row">
            <svg-icon :name="getIconName(languageRow.key)" class="language-switcher__icon" />
            <div
                v-for="locale in languageRow.locales"
                :key="locale.code"
                class="language-switcher__option"
                @click="handleOptionClick(locale.code)"
            >
                <nuxt-link v-if="isSameTld(locale)" :to="formatLocalePath(locale.code)">
                    {{ locale.name }}
                </nuxt-link>
                <a v-else :href="getFullUrl(locale)">
                    {{ locale.name }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { compile } from 'path-to-regexp/dist';
import allLocales from '~/config/locales.json';

const englishCode = 'en';
const germanCode = 'de';
const finnishCode = 'fi';
const finnishSvCode = 'fi-sv';
const swissGermanCode = 'ch';
const swissFrenchCode = 'fr';

export default {
    computed: {
        formattedLocales() {
            const common = [];
            const languageRows = [];

            allLocales.forEach(locale => {
                if (locale.code === englishCode || locale.code === germanCode) {
                    common.push(locale);
                } else if (locale.code === finnishSvCode) {
                    const index = languageRows.findIndex(row => row.key === finnishCode);
                    languageRows[index].locales.push(locale);
                } else if (locale.code === swissFrenchCode) {
                    const index = languageRows.findIndex(row => row.key === swissGermanCode);
                    languageRows[index].locales.push(locale);
                } else {
                    languageRows.push({ key: locale.code, name: locale.name, locales: [locale] });
                }
            });

            languageRows.sort((a, b) => (a.name > b.name ? 1 : -1));
            common.sort((a, b) => (a.name > b.name ? 1 : -1));

            languageRows.push({ key: 'common', locales: common });

            return languageRows;
        },
    },
    methods: {
        getLocalizedPathForRoute(routeName, localeCode) {
            const i18nPaths = {
                magazine: {
                    se: '/magasin',
                    'fi-sv': '/magasin',
                    en: '/magazine',
                    de: '/magazin',
                    fi: '/lehti',
                    no: '/magasin',
                    dk: '/magasin',
                    ch: '/magazin',
                    fr: '/magazine',
                },
            };

            return i18nPaths[routeName][localeCode];
        },
        getPathPrefix(locale) {
            const isComDefault = locale.tld === 'com' && locale.code === 'en';
            const isChDefault = locale.tld === 'ch' && locale.code === 'ch';

            if (isComDefault || isChDefault) {
                return '';
            }

            return `/${locale.code}`;
        },
        isSameTld(locale) {
            return locale.tld === this.$i18n.localeProperties.tld;
        },
        formatLocalePath(localeCode) {
            const routeParams = this.$store.getters.localeRouteParams(localeCode);

            if (routeParams?.name) {
                return this.localePath({ name: routeParams.name });
            }

            if (Object.keys(this.$route.query).length) {
                const newQuery = this.translateQueryParameters(this.$route.query, localeCode);
                const switchedPath = this.switchLocalePath(localeCode);
                const splitSwitchedPath = switchedPath.split('?');
                const route = this.localeRoute({ path: splitSwitchedPath[0], query: { ...newQuery } }, localeCode);

                return route.fullPath;
            }

            return this.switchLocalePath(localeCode);
        },
        getRouteNuxtI18nPathForLocale(route, localeCode) {
            return route.matched[0]?.components?.default?.options?.nuxtI18n?.paths?.[localeCode];
        },
        removeCurrentLocalePathPrefix(path) {
            const pathPrefix = this.getPathPrefix(this.$i18n.localeProperties);

            return path.replace(new RegExp(`^${pathPrefix}`), '');
        },
        addTranslatedQueryParams(url, locale) {
            if (!Object.keys(this.$route.query).length) {
                return url;
            }

            const translatedQuery = this.translateQueryParameters(this.$route.query, locale.code);

            const newUrl = new URL(url);
            newUrl.search = new URLSearchParams(translatedQuery);

            return newUrl.href;
        },
        /**
         * Replaces route params in path with values from routeParams object
         *
         * @param {string} path E.g. /magazine/:slug?
         * @param {object} routeParams E.g. { slug: 'my-slug' }
         * @returns {string} E.g. /magazine/my-slug
         */
        replaceRouteParams(path, routeParams) {
            const allParams = { ...this.$route.params, ...routeParams };

            return compile(path)(allParams);
        },
        /**
         * Tries to find a full URL for equivalent page at the given locale
         *
         * There are three ways we can get the full URL:
         *
         * 1. If there is a full URL set in routeParams we use that
         * 2. Take the locale's base URL & translate the path using nuxtI18n config of the current page component
         * 3. Take the locale's base URL & add the current route path, untranslated
         *
         * @param {object} locale
         * @returns {string}
         */
        getFullUrl(locale) {
            const baseUrl = locale.tld === 'ch' ? this.$config.baseUrlCh : this.$config.baseUrlCom;
            const pathPrefix = this.getPathPrefix(locale);
            const routeParams = this.$store.getters.localeRouteParams(locale.code);

            // Allows setting the full URL with SET_ROUTE_PARAMS
            if (routeParams?.fullUrl) {
                return this.addTranslatedQueryParams(routeParams.fullUrl, locale);
            }

            // Use localeRouteParams 'name' (so we can get localized path of page) or current page component's nuxtI18n path translations if available
            let path = routeParams.name
                ? this.getLocalizedPathForRoute(routeParams.name, locale.code)
                : this.getRouteNuxtI18nPathForLocale(this.$route, locale.code);

            if (!path) {
                path = this.removeCurrentLocalePathPrefix(this.$route.path);
            }

            path = this.replaceRouteParams(path, routeParams);

            return this.addTranslatedQueryParams(`${baseUrl}${pathPrefix}${path}`, locale);
        },
        handleOptionClick(code) {
            return this.$emit('optionSelected', code);
        },
        getIconName(code) {
            if (code === 'common') {
                return 'globe';
            }

            return `${code}_round_flag`;
        },
        findMatchingQueryParameterForLocale(transKey, localeCode) {
            const locale = allLocales.find(l => l.code === localeCode);

            return Object.keys(locale.queryTranslationsPlaceholder).find(
                key => locale.queryTranslationsPlaceholder[key] === transKey
            );
        },
        translateQueryParameters(query, localeCode) {
            const customQuery = {};
            Object.keys(query).forEach(queryString => {
                if (Object.hasOwn(this.$i18n.localeProperties.queryTranslationsPlaceholder, queryString)) {
                    const transKey = this.$i18n.localeProperties.queryTranslationsPlaceholder[queryString];
                    const queryTrans = this.findMatchingQueryParameterForLocale(transKey, localeCode);
                    customQuery[queryTrans] = JSON.parse(query[queryString]);
                } else {
                    customQuery[queryString] = query[queryString];
                }
            });

            return customQuery;
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

$highlighted-language-background-padding: 8px;

.language-switcher {
    &__row {
        display: flex;
        align-items: center;
        min-height: 32px;

        @media (max-width: #{$bp-hamburger-menu-max}) {
            width: fit-content;

            &:first-of-type {
                margin-top: 28px;
            }

            &:last-of-type {
                margin-bottom: 28px;
            }
        }

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        &:last-of-type {
            border-top: 1px solid $darkest-gray;
            padding-top: 16px;
            padding-right: 20px;

            @include menu-breakpoint {
                border-top: 1px solid $gray;
            }
        }
    }

    &__option {
        // Separator
        &:not(:last-of-type):after {
            content: '|';
            width: 5px;
            @include font-size(16px);
            font-weight: 400;
            margin: 0 12px;
        }

        a {
            font-weight: normal;
            &.nuxt-link-active {
                border: none;
            }
            &.nuxt-link-exact-active {
                // Override styling from MainMenuItem
                border-bottom: none;
                border-radius: $default-border-radius;
                background: $gray;
                padding: 8px;
                font-weight: 600;
                margin: 0 -$highlighted-language-background-padding; // Go over separators spacing
                @media (max-width: #{$bp-hamburger-menu-max}) {
                    color: $primary;
                }
            }
        }
    }
}
</style>
